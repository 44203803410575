// Note: Requires flexslider 2
jQuery(document).ready(function($) {
	$('.row-checkered-slider:not(.one-slide) .flexslider').flexslider({
		animation: "slide",
		prevText: "",
		nextText: "",
		controlNav: false,
		directionNav: true,
	});
}); /* end of as page load scripts */
